<template>
    <div class="block">
        <div class="flex w-full justify-center">
            <div class="bg-green-500 rounded-full p-5">
                <b-icon
                    class="text-white"
                    :icon="data.Icon"
                    size="is-large">
                </b-icon>
            </div>
        </div>
        <div class="flex w-full justify-center mt-3">
            <span class="font-bold">{{ data.Description }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    }
}
</script>
